import Axios from 'axios';

const axios = Axios.create({
	baseURL: process.env.REACT_APP_API_ADDRESS
})

axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') || "";

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	return Promise.reject(error);
});

export default axios;