import './LinkManagerPage.css';
import { Input, Button } from 'antd';
import HorizontalSeparator from '../HorizontalSeparator/HorizontalSeparator';
import { useEffect, useState } from 'react';
import axios from '../AxiosInstance.js'

function LinkManagerPage() {
	
	const [targets, setTargets] = useState([])
	const [link, setLink] = useState('')
	const [productType, setProductType] = useState('')
	const [loading, setLoading] = useState(false)	

	const updateTargets = () => {
		axios.get('/api/targets').then((resp) => {
			console.log('/api/targets', resp.data)
			if (resp.data.success){
				setTargets(resp.data.data)
			} else {
				alert(resp.data.msg)
			}
		})
	}

	useEffect(() => {
		updateTargets()
	}, [])

	const onAddLink = () => {
		setLoading(true)
		axios.post('/api/targets', {"url": link, "product_type": productType}).then((resp) => {
			console.log('/api/targets', resp.data)
			setLoading(false)
			if (resp.data.success){
				updateTargets()
			} else {
				alert(resp.data.msg)
			}
		})
	}
	
	const deleteTarget = (target) => {
		axios.delete('/api/targets', {data: {"url": target.url}}).then((resp) => {
			console.log('DEL /api/targets', resp.data)
			if (resp.data.success){
				setTargets(targets.filter((target) => target.url !== link))
			} 
			alert(resp.data.msg)
			
		})
	}

	return (
		<div className='link-manager-container'>
			<h1>Link Manager</h1>
			<div className='link-form'>
				<Input placeholder="Link" onChange={(e)=>{setLink(e.target.value)}}/>
				<Input placeholder="Product Type" onChange={(e)=>{setProductType(e.target.value)}}/>
				<Button type="primary" onClick={()=>{onAddLink()}} loading={loading}>Add</Button>
			</div>
			<HorizontalSeparator />
			<div className='link-list'>
			<div className='link-header link-item'>
				<div className='link-header-url'>URL</div>
				<div className='link-header-product-type'>Product Type</div>
				<div className='link-header-actions'>Actions</div>
			</div>
				{targets.map((target, index) => {
					return (
						<div className='link-item' key={index}>
							<div className='link-item-url'>{target.url}</div>
							<div className='link-item-product-type'>{target.product_type}</div>
							<Button danger onClick={(e)=>{deleteTarget(target)}}>Delete</Button>
						</div>
					)
				})}	
			</div>
		</div>
	);
}

export default LinkManagerPage;