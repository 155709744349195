import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import DashboardPage from './DashboardPage/DashboardPage';
import LinkManagerPage from './LinkManagerPage/LinkManagerPage';
import LoginPage from './LoginPage/LoginPage';
import ReportsPage from './ReportsPage/ReportsPage';
import SettingsPage from './SettingsPage/SettingsPage';
import reportWebVitals from './reportWebVitals';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
	{
		path: "/",
		element: <LoginPage />,
	},
	{
		path: "/login",
		element: <LoginPage />,
	},
	{
		path: "/dashboard",
		element: <DashboardPage />,
		children: [
			{
				path: "links",
				element: <LinkManagerPage/>,
			},
			{
				path: "reports",
				element: <ReportsPage/>,
			},
			{
				path: "settings",
				element: <SettingsPage/>,
			}
		]
	}
]);

const root = ReactDOM.createRoot(document.getElementById("root")).render(
	// Strict mode removed for now, as it causes a warning in the console when used together with ant design menu: https://github.com/ant-design/ant-design/issues/22493
	// <React.StrictMode>
		<RouterProvider router={router} />
	// </React.StrictMode>
);

// root.render(
// 	<React.StrictMode>
// 		<App />
// 	</React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
