import './ReportsPage.css';
import { DatePicker, Select, InputNumber, Button } from 'antd';
import HorizontalSeparator from '../HorizontalSeparator/HorizontalSeparator';
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import axios from '../AxiosInstance.js'
import React, { useState, useEffect } from 'react';
import { format, parse, addDays, isBefore, startOfToday } from 'date-fns'

function ReportsPage() {
	const [periodStartDate, setPeriodStartDate] = useState(format(addDays(startOfToday(), -7), 'yyyy-MM-dd'));
	const [periodEndDate, setPeriodEndDate] = useState(format(startOfToday(), 'yyyy-MM-dd'));

	const [productType, setProductType] = useState('All');
	const [brand, setBrand] = useState('All');
	const [size, setSize] = useState('All');
	const [metric, setMetric] = useState('Unit Sales');
	const [periodGrouping, setPeriodGrouping] = useState(1);

	const [productTypes, setProductTypes] = useState([]);
	const [brands, setBrands] = useState([]);
	const [sizes, setSizes] = useState([]);
	
	const [salesData, setSalesData] = useState([]);
	const [brandChartData, setBrandChartData] = useState([]);
	const [productTypeChartData, setProductTypeChartData] = useState({});
	const [skuChartData, setSkuChartData] = useState({});

	const [skuChartStyles, setSkuChartStyle] = useState({});

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if(productTypes.length === 0) {
			
			axios.get('/api/filter_options').then((response) => {
				const data2SelectOptions = (data) => {
					data.push('All')
					return data.map((d, i) => {
						return {value: d, label: d}
					})
				}
				setProductTypes(data2SelectOptions(response.data.data.product_types))
				setBrands(data2SelectOptions(response.data.data.brands))
				setSizes(data2SelectOptions(response.data.data.sizes))
			})
		}
		
	}, []);

	useEffect(() => {
		if(brands.length > 0) {
			fetchData()
		}
	}, [brands])


	const onDateRangeChange = (date, dateString) => {
		setPeriodStartDate(dateString[0]);
		setPeriodEndDate(dateString[1]);
	}

	const metrics = [
		{ value: 'Unit Sales', label: 'Unit Sales'},
		{ value: 'Dollar Sales', label: 'Dollar Sales'},
		{ value: 'Stock Available', label: 'Stock Available'},
	]

	const getDifferentValues = (dataArray, key) => {
		const values = [];
		dataArray.forEach((data) => {
			if (!values.includes(data[key])) {
				values.push(data[key]);
			}
		});
		return values;
	}

	const groupDataByPeriod = (data, periodSize) => {
		const groupedData = [];
		if(data.length === 0) {
			alert('Not enough data to generate charts')
			return groupedData;
		}

		let smallerDate = parse(data[0].data[0].x, 'yyyy-MM-dd', new Date())
		data.forEach((d) => {
			let date = parse(d.data[0].x, 'yyyy-MM-dd', new Date())
			if (isBefore(date, smallerDate)) {
				smallerDate = date;
			}
		})
		
		data.forEach((axis) => {
			let periodStartDate = new Date(smallerDate.toISOString())
			let dateLimit = addDays(smallerDate, periodSize)
			let axisData = []
			let periodData = {x: format(periodStartDate, 'yyyy-MM-dd', new Date()), y: 0}
			while (axis.data.length > 0) {
				let curDate = parse(axis.data[0].x, 'yyyy-MM-dd', new Date())
				if (curDate < dateLimit) {
					periodData.y += axis.data[0].y;
					axis.data.shift()
				} else {
					axisData.push(periodData)
					periodStartDate = dateLimit;
					dateLimit = addDays(periodStartDate, periodSize)
					periodData = {x: format(periodStartDate, 'yyyy-MM-dd'), y: axis.data.shift().y}
				}
			}
			axisData.push(periodData)

			groupedData.push({
				id: axis.id,
				data: axisData
			})
			
		})
		return groupedData
	}

	const generateBrandData = (data) => {
		const brandData = [];
		const brands = getDifferentValues(data, 'brand');
		let dailySalesPerBrand = {}
		brands.forEach((brand) => {
			dailySalesPerBrand[brand] = {}
		})
		data.forEach((d) => {
			let day = d.date.split('T')[0]
			if (day in dailySalesPerBrand[d.brand]) {
				if(metric === 'Unit Sales') {
					dailySalesPerBrand[d.brand][day] += d.sales;
				}
				if(metric === 'Dollar Sales') {
					dailySalesPerBrand[d.brand][day] = parseFloat(dailySalesPerBrand[d.brand][day]) + parseFloat(d.sales * d.price)
					dailySalesPerBrand[d.brand][day] = parseFloat(dailySalesPerBrand[d.brand][day]).toFixed(2)
				}
				if(metric === 'Stock Available') {
					dailySalesPerBrand[d.brand][day] += d.stock;
				}
			} else {
				if(metric === 'Unit Sales') {
					dailySalesPerBrand[d.brand][day] = d.sales;
				}
				if(metric === 'Dollar Sales') {
					dailySalesPerBrand[d.brand][day] = (d.sales * d.price).toFixed(2);
				}
				if(metric === 'Stock Available') {
					dailySalesPerBrand[d.brand][day] = d.stock;
				}
			}
		})
		brands.forEach((brand) => {
			const brandDataPoints = [];
			for (const [key, value] of Object.entries(dailySalesPerBrand[brand])) {
				brandDataPoints.push({x: key, y: value})
			}
			brandData.push({
				id: brand,
				data: brandDataPoints
			})
		})
		let groupedData = groupDataByPeriod(brandData, periodGrouping);
		console.log('brandData', groupedData)
		return groupedData
	}

	const generateProductTypeData = (data) => {
		const productTypeData = [];
		const productTypes = getDifferentValues(data, 'type');
		let salesPerType = {}
		productTypes.forEach((type) => {
			salesPerType[type] = 0
		})
		data.forEach((d) => {
			if(metric === 'Unit Sales') {
				salesPerType[d.type] += d.sales;
			}
			if(metric === 'Dollar Sales') {
				salesPerType[d.type] = parseFloat(salesPerType[d.type]) + parseFloat(d.sales * d.price)
				salesPerType[d.type] = parseFloat(salesPerType[d.type]).toFixed(2)
			}
			if(metric === 'Stock Available') {
				salesPerType[d.type] += d.stock;
			}
		})

		productTypes.forEach((type) => {
			productTypeData.push({
				id: type,
				label: type,
				value: salesPerType[type]
			})
		})
		
		return productTypeData
	}

	const generateSkuChartData = (data, brand) => {
		const skuData = [];
		const skus = []
		const addedSkus = []
		let chartHeight = 120
		data.forEach((d) => {
			if (!addedSkus.includes(d['sku'])) {
				if (metric !== 'Stock Available' && d['sales']===0) {
					return
				}
				skus.push({
					'sku': d['sku'],
					'name': `${d['name']} - ${d['variant']}`
				})
				addedSkus.push(d['sku'])
				chartHeight += 15
			}
		});

		setSkuChartStyle(prevState => ({
			...prevState,
			[brand]: {height: `${chartHeight}px`, width: '100%'}
		}));

		let salesPerSku = {}
		skus.forEach((sku) => {
			salesPerSku[sku['sku']] = 0
		})

		data.forEach((d) => {
			if(metric === 'Unit Sales') {
				salesPerSku[d.sku] += d.sales;
			}
			if(metric === 'Dollar Sales') {
				salesPerSku[d.sku] = parseFloat(salesPerSku[d.sku]) + parseFloat(d.sales * d.price)
				salesPerSku[d.sku] = parseFloat(salesPerSku[d.sku]).toFixed(2)
			}
			if(metric === 'Stock Available') {
				salesPerSku[d.sku] += d.stock;
			}
		})

		skus.forEach((sku) => {
			skuData.push({
				'id': sku['name'],
				'value': salesPerSku[sku['sku']] < 0? 0: salesPerSku[sku['sku']],
			})
		})
		return skuData
	}

	const processData = (data) => {
		setSalesData(data)
		setBrandChartData(generateBrandData(data))
		
		let productChartData = {
			'All Products': []
		}
		if (metric === 'Stock Available') {
			productChartData['All Products'] = generateProductTypeData(data)
		} else {
			productChartData['All Products'] = generateProductTypeData(data.filter((d) => d.sales > 0))
		}

		brands.forEach((brand) => {
			if(brand.value !== ''){
				let d = []
				if (metric === 'Stock Available') {
					d = generateProductTypeData(data.filter((d) => d.brand === brand.value))
				} else {
					d = generateProductTypeData(data.filter((d) => d.brand === brand.value && d.sales > 0))
				}
				if (d.length > 0){
					productChartData[brand.value] = d
				}
			}
		})
		console.log('productChartData', productChartData)
		setProductTypeChartData(productChartData)
		
		let skuChartData = {
			'All Products': generateSkuChartData(data, 'All Products')
		}
		brands.forEach((brand) => {
			if(brand.value !== ''){
				let d = generateSkuChartData(data.filter((d) => d.brand === brand.value), brand.value)
				if (d.length > 0){
					skuChartData[brand.value] = generateSkuChartData(data.filter((d) => d.brand === brand.value))
				}
			}
		})
		
		// not the best solution, I know and I'm sorry :(
		// generateSkuChartData is returning an array with duplicated IDs, so this adds all the values of skuChartData with the same id to de-duplicate it
		Object.keys(skuChartData).forEach((key) => {
			let skuData = skuChartData[key]
			let skuDataWithoutDuplicates = []
			let addedSkus = []
			skuData.forEach((sku) => {
				if (!addedSkus.includes(sku.id)) {
					skuDataWithoutDuplicates.push(sku)
					addedSkus.push(sku.id)
				} else {
					let index = skuDataWithoutDuplicates.findIndex((s) => s.id === sku.id)
					skuDataWithoutDuplicates[index].value += sku.value
				}
			})
			skuChartData[key] = skuDataWithoutDuplicates
		})
		console.log('skuChartData', skuChartData)
		setSkuChartData(skuChartData)
	}

	const fetchData = () => {
		setIsLoading(true);
		let apiEndpoint = '/api/sales?';
		
		if(periodStartDate && periodEndDate) {
			apiEndpoint += `start_date=${periodStartDate}&end_date=${periodEndDate}`;
		}
		if(productType !== 'All') {
			apiEndpoint += `&product_type=${productType}`;
		}
		if(brand !== 'All') {
			apiEndpoint += `&brand=${brand}`;
		}
		if(size !== 'All') {
			apiEndpoint += `&size=${size}`;
		}
		console.log('apiEndpoint', apiEndpoint)

		let page=0
		let completeData = []
		let hanldeResp = (resp) => {
			console.log('page '+page+' resp', resp.data)
			let data = resp.data.data
			if (data.length === 0) {
				processData(completeData)
				setIsLoading(false)
			} else {
				completeData = completeData.concat(data)
				page++
				return axios.get(apiEndpoint+`&page=${page}`).then(hanldeResp)
			}
		}
		axios.get(apiEndpoint+`&page=${page}`).then(hanldeResp)

	}

	const generateCsvData = () => {
		let csvData = {}
		salesData.forEach((d) => {
			let day = d.date.split('T')[0]
			let sku = d.sku
			if (sku in csvData) {

				if (day > csvData[sku]['day']) {
					csvData[sku]['day'] = day
					csvData[sku]['stock'] = Math.max(d.stock,0)
				}
				csvData[sku]['sales'] += d.sales


			} else {
				csvData[sku] = {
					'sku': sku,
					'name': d.name,
					'variant': d.variant,
					'brand': d.brand,
					'type': d.type,
					'size': d.size,
					'sales': d.sales,
					'price': d.price,
					'stock': Math.max(d.stock,0),
					'day': d.sales
				}
			}
		})
		let csvRows = []
		Object.keys(csvData).forEach((key) => {
			if (csvData[key]['sales'] === 0) {
				return
			}
			csvRows.push(csvData[key])
		})
		return csvRows
	}

	const downloadData = () => {
		let csvRows = generateCsvData(salesData)
		console.log('csvRows', csvRows)
		let rows = [`Period: ${periodStartDate} to ${periodEndDate}`,'"Product Description","Brand", "Product Type", "Size", "Unit Sales", "Stock Available"']
		csvRows.forEach((row) => {
			rows.push(`"${row['name']} / ${row['variant']}","${row['brand']}","${row['type']}","${row['size']}",${row['sales']},${row['stock']}`)			
		})
		console.log('rows', rows)
		let csvString = rows.join('\n')

		let fileName = `${periodStartDate}-${periodEndDate}.csv`

		const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' })
		const objUrl = URL.createObjectURL(blob)
		const link = document.createElement('a')
		link.setAttribute('href', objUrl)
		link.setAttribute('download', fileName)
		link.click()
	}

	const renderSearchButton = () =>{
		if (isLoading){
			return (
				<Button type='primary' loading>Search</Button>
			)
		}
		return (
			<Button type='primary' onClick={fetchData}>Search</Button>
		)
	}

	return (
		<div className='reports-page-container'>
			<h1>Reports</h1>
			<div className='filters-form'>
				<div className='filter-cell'>
					<span className='filter-label'>Date Range</span>
					<DatePicker.RangePicker onChange={onDateRangeChange}/>
				</div>
				<div className='filter-cell'>
					<span className='filter-label'>Product Type</span>
					<Select defaultValue="All" options={productTypes} onChange={(v)=>{setProductType(v)}} />
				</div>
				<div className='filter-cell'>
					<span className='filter-label'>Brand</span>
					<Select defaultValue="All" options={brands} onChange={(v)=>{setBrand(v)}}/>
				</div>
				<div className='filter-cell'>
					<span className='filter-label'>Size</span>
					<Select defaultValue="All" options={sizes} onChange={(v)=>{setSize(v)}} />
				</div>
				<div className='filter-cell'>
					<span className='filter-label'>Metric</span>
					<Select defaultValue="Unit Sales" options={metrics} value={metric} onChange={(v)=>{setMetric(v)}}/>
				</div>
				<div className='filter-cell'>
					<span className='filter-label'>Period Grouping</span>
					<InputNumber placeholder="Period Grouping" min={1} style={{width:"100%"}} value={periodGrouping} onChange={(v)=>{setPeriodGrouping(v)}}/>
				</div>
			</div>
			<div className='btn-filter-wrapper'>
				{renderSearchButton()}
			</div>
			<HorizontalSeparator />
			<div className='reports-container'>
				<div className='report-header'>Brand Summary</div>
				<div className='big-chart-container'>
					<div className='chart-wrapper'>
						<ResponsiveLine 
							margin={{ top: 16, right: 64, bottom: 70, left: 36 }}
							xScale={{ type: 'point' }}
							pointSize={5}
							pointColor={{ theme: 'background' }}
							pointBorderWidth={2}
							pointBorderColor={{ from: 'serieColor' }}
							useMesh={true}
							data={brandChartData}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 30,
								legendOffset: 50,
								legendPosition: 'middle'
							}}
							legends={[
								{
									anchor: 'bottom',
									direction: 'row',
									translateX: 0,
									translateY: 60,
									itemsSpacing: 2,
									itemDirection: 'left-to-right',
									itemWidth: 80,
									itemHeight: 12,
									itemOpacity: 0.75,
									symbolSize: 12,
									symbolBorderColor: 'rgba(0, 0, 0, .5)',
									effects: [
										{
											on: 'hover',
											style: {
												itemBackground: 'rgba(0, 0, 0, .03)',
												itemOpacity: 1
											}
										}
									]
								}
							]}
							
						/>
					</div>
				</div>
			</div>
			<div className='reports-container'>
				<div className='report-header'>Product Type</div>
				<div className='chart-container'>
					{Object.keys(productTypeChartData).map((key, i) => {
						return (
							<div className='chart-wrapper' key={i}> 
								<div className='chart-title'>{key}</div>
								<ResponsivePie
									data={productTypeChartData[key]}
									margin={{ top: 16, right: 80, bottom: 80, left: 80 }}
									innerRadius={0.4}
									padAngle={0.7}
									cornerRadius={3}
									activeOuterRadiusOffset={8}
									borderWidth={1}
									borderColor={{
										from: 'color',
										modifiers: [ [ 'darker', 0.2 ]]
									}}
									arcLinkLabelsSkipAngle={10}
									arcLinkLabelsTextColor="#333333"
									arcLinkLabelsThickness={2}
									arcLinkLabelsColor={{ from: 'color' }}
									arcLabelsSkipAngle={10}
									legends={[
										{
											anchor: 'right',
											direction: 'column',
											justify: false,
											translateX: 0,
											translateY: 0,
											itemsSpacing: 0,
											itemWidth: 100,
											itemHeight: 36,
											itemTextColor: '#999',
											itemDirection: 'left-to-right',
											
										}
									]}	
									
								/>	
						</div>)
					})}
				</div>
			</div>
			<div className='reports-container'>
				<div className='report-header'>SKU Summary</div>
				<div className='chart-container'>
					{Object.keys(skuChartData).map((key, i) => {
						return (
							<div style={skuChartStyles[key]} key={i}>
								<div className='chart-title'>{key}</div>
								<ResponsiveBar
									data={skuChartData[key]}
									margin={{ top: 50, right: 130, bottom: 50, left: 500 }}
									padding={0.3}
									colors={{ scheme: 'nivo' }}
									groupMode="grouped"
									layout="horizontal"
									axisLeft={{
										tickSize: 10,
										tickPadding: 20,
										tickRotation: 0,
										legendPosition: 'middle',
										legendOffset: 0
									}}
									axisBottom={{
										tickSize: 10,
										tickPadding: 20,
										tickRotation: 0,
										legendPosition: 'middle',
										tickValues: 3,
									}}
								/>
							</div>
						)
					})}
				</div>
			</div>
			<div className='download-container'>
				<Button type='primary' onClick={()=>{downloadData()}}>Download CSV</Button>
			</div>
		</div>
	);
}

export default ReportsPage;