import './LoginPage.css';
import { Input, Button } from 'antd';
import axios from '../AxiosInstance.js'
import React, { useState, useEffect } from 'react';

function LoginPage() {

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const siteUrl = window.location.search;
		let token = new URLSearchParams(siteUrl).get('token');
		if (token) {
			localStorage.setItem('token', token);
			window.location.href = '/dashboard/reports';
		}

	}, [])

	const onLogin = () => {
		setIsLoading(true)
		axios.post('/api/login', {"email": email, "password":password}).then((resp) => {
			if(resp.data.success){
				localStorage.setItem('token', resp.data.data)
				window.location.href = '/dashboard/reports';
			} else {
				alert(resp.data.msg)
			}
			setIsLoading(false)
		})
	}

	const sendResetEmail = () => {
		axios.post('/api/reset_password', {"email": email}).then((resp) => {
			alert(resp.data.msg)
		})
	}


	return (
		<div className="login-container">
			<div className='login-form pane'>
				<h1>Login</h1>
				<Input placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}}/>
				<Input.Password placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}} onKeyUp={(e) => {if (e.key === "Enter") onLogin() }}/>
				<Button type="primary" onClick={()=>{onLogin()}} loading={isLoading	}>Login</Button>
				<div className='forgot-wrapper'><a href="#" onClick={()=>{sendResetEmail()}}>Send reset link</a></div>
			</div>
		</div>
	);
}

export default LoginPage;