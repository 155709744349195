import './HorizontalSeparator.css';

function HorizontalSeparator() {

	return (
		<div className="horizontal-separator">
		</div>
	);
}

export default HorizontalSeparator;