import './SettingsPage.css';
import { Input, Button } from 'antd';
import axios from '../AxiosInstance.js'
import React, { useState } from 'react';

function SettingsPage() {

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const onChangePwd = (e) => {

		if (password === confirmPassword) {
			axios.post('/api/change_password', {"password": password}).then((resp) => {
				alert(resp.data.msg)
			})
		} else {
			alert('Passwords do not match')
		}
	}

	return (
		<div className='settings-page-container'>
			<div className='settings-form pane'>
				<h1>Settings</h1>
				<Input.Password placeholder="New Password" onChange={(e)=>{setPassword(e.target.value)}}/>
				<Input.Password placeholder="Confirm Password" onChange={(e)=>{setConfirmPassword(e.target.value)}}/>
				<Button type="primary" onClick={onChangePwd}>Apply</Button>
			</div>
		</div>
	);
}

export default SettingsPage;