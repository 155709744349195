import { Outlet } from "react-router-dom";
import { Menu } from 'antd';
import { BarsOutlined, SettingOutlined, AreaChartOutlined, LogoutOutlined } from '@ant-design/icons';

import './DashboardPage.css';

function DashboardPage() {

	const items = [
		{
			label: 'Reports',
			key: 'reports',
			icon: <AreaChartOutlined />,
		},
		{
			label: 'Links Manager',
			key: 'links',
			icon: <BarsOutlined />,
		},
		{
			label: 'Settings',
			key: 'settings',
			icon: <SettingOutlined />,
		},
		{
			label: 'Logout',
			key: 'logout',
			icon: <LogoutOutlined />,
			style: {marginLeft: 'auto'}
		}
	]

	const itemClicked = (path) => {
		if (path === 'logout'){
			localStorage.removeItem('token')
			window.location.href = '/login'
			return
		} 
		window.location.href = '/dashboard/' + path
	}

	return (
		<div className="dashboard-container">
			<div className="horizontal-menu">
				<Menu mode="horizontal" items={items} onClick={(e)=>{itemClicked(e.key)}} />
			</div>
			

			<Outlet />
		</div>
	);
}

export default DashboardPage;